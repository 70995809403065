$datatable-collapsed-unfold : $ni-plus-sm !default;
$datatable-collapsed-fold : $ni-minus-sm !default;

table.dataTable {
    clear: both;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
    text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
    width: auto;
    display: inline-block;
    @extend .custom-select;
}

div.dataTables_wrapper div.dataTables_length label {
    display: inline-flex;
    align-items: center;
}

div.dataTables_wrapper div.dataTables_length span {
    margin-left: .5rem;
    margin-right: .5rem;
}

div.dataTables_wrapper div.dataTables_filter {}

div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_filter input {
    display: inline-block;
    @extend .form-control;
}

div.dataTables_wrapper div.dataTables_info {
    padding: .5rem 0;
    white-space: nowrap;
    font-size: .75rem;
}

div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    justify-content: center;
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    width: 200px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
}

div.dataTables_wrapper label {
    margin-bottom: 0;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
    padding-right: 30px;
}

table.dataTable thead>tr>th:active,
table.dataTable thead>tr>td:active {
    outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\2191";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "\2193";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
    opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none;
}

div.dataTables_scrollFoot>.dataTables_scrollFootInner {
    box-sizing: content-box;
}

div.dataTables_scrollFoot>.dataTables_scrollFootInner>table {
    margin-top: 0 !important;
    border-top: none;
}

@media screen and (max-width: 767px) {

    div.dataTables_wrapper div.dataTables_info,
    div.dataTables_wrapper div.dataTables_paginate {
        text-align: center;
    }
}

table.dataTable.table-sm>thead>tr>th {
    padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
    top: 5px;
    right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
    top: 5px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
    border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
    border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
    border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row {
    margin: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:first-child {
    padding-left: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
    padding-right: 0;
}

@media (min-width:768px) {
    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        justify-content: flex-start;
    }
}


table.dataTable.dtr-inline.collapsed>tbody>tr:not(.child)>td:first-child,
table.dataTable.dtr-inline.collapsed>tbody>tr:not(.child)>th:first-child {
    cursor: pointer;

    &:before {
        content: $datatable-collapsed-unfold;
        font-family: $nk-dashlite-font;
        margin-right: 0.4rem;
    }
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child {
    &:before {
        content: $datatable-collapsed-fold;
    }
}


@media (max-width:575px) {
    div.dataTables_wrapper div.dataTables_paginate {
        ul.pagination {
            justify-content: flex-start;

            li:not(.previous):not(.next) {
                display: none;
            }

            .page-link {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    div.dataTables_wrapper div.dataTables_info {
        text-align: right !important;
    }
}

.dataTable {

    // tr{
    //   white-space: nowrap;
    // }
    td {

        // white-space: nowrap;
        &:focus {
            outline: none;
        }
    }
}

.datatable {
    &-wrap {
        border: 1px solid $border-light;
        border-radius: $border-radius;
    }
}

.dtr-details {
    padding-left: 0.25rem;

    .dtr-title {
        font-weight: $fw-bold;
        margin-right: 0.25rem;
    }
}


//////

.nk-tb-col-check,
.nk-tb-col-tools {
    pointer-events: none;

    >* {
        pointer-events: auto;
    }

    &:before,
    &:after {
        display: none !important;
    }
}

.nk-tb-col-check {
    padding-right: .5rem !important;
}

.ordering-icon {
    max-width: .5rem;
}

@import "buttons.bootstrap4";

.stats-table {
    display: grid;
    grid-template-columns: minmax(1rem, max-content) auto minmax(1rem, max-content);
    grid-column-gap: .5rem;
    grid-template-rows: 1fr;
    align-items: center;
    align-content: stretch;
    justify-content: stretch;

    .c-1 {
        grid-column: 1;
        min-width: 1rem;
        max-width: fit-content;
        justify-self: center;
    }

    .c-2 {
        grid-column: 2;
        min-width: 1rem;
        max-width: fit-content;
        justify-self: start;

        span {
            width: 100%;
            white-space: "nowrap";
        }
    }

    .c-3 {
        grid-column: 4;
        min-width: fit-content;
        justify-self: end;
    }

    @for $row-num from 1 to 30 {
        .r-#{$row-num} {
            grid-row: #{$row-num};
        }
    }

    @for $row-num from 1 to 30 {
        .r-#{$row-num} {
            +.c-3 {
                line-height: 0.9rem;
            }
        }
    }
}