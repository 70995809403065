.animate-container {
    position: relative;
    overflow: hidden;
  }
  
  .animate-text {
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
  }
  
  .animate-placeholder {
    position: relative;
    visibility: hidden;
  }
  