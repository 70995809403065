@import "../../../assets/scss/dashlite_variables";

/* ===== BEGIN VARIABLES ===== */
$video-radius: 1rem;
/* =====  END VARIABLES  ===== */

@mixin cool-scrollbar {
  /* ===== Scrollbar CSS ===== */
  overflow: overlay;
  /* Firefox */
  scrollbar-width: 0.5rem;
  scrollbar-color: $base-dark #00000000;
  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background: #00000000;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $base-dark;
    border-radius: 10px;
    border: 1px solid #ffffff;
    visibility: hidden;
  }
  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

.hidden {
  visibility: hidden;
}
.phone-icon-rotate-135-deg {
  rotate: 135deg;
}
.nk-header-brand-name {
  color: $light;
}
.logo-light {
  filter: invert(0.9);
}
.vd {
  &-call {
    &-container {
      color: $light;
      // Basic color to gracefully degrade
      background-color: $base-dark;
      // Gradient for webkit browsers
      background-image: -webkit-radial-gradient(ellipse at center, $accent-dark, $base-dark);
      // Gradient for other browsers
      background-image: radial-gradient(ellipse at center, $accent-dark, $base-dark);

      min-height: 100vh;
      width: 100vw;
      min-width: 100vw;
      max-width: 100vw;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: min-content auto min-content;
      justify-content: stretch;
      align-content: stretch;
      button {
        margin: 0.25rem;
        &.icon {
          border-radius: 50%;
          width: 3rem;
          height: 3rem;
          padding: 0;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
        }
      }
      .call-info-content {
        grid-column: 1;
        grid-row: 2;
        color: $light;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: repeat(1fr);
        border: 1px $light solid;
        overflow-x: scroll;
        .key {
          grid-column: 1;
          justify-self: end;
          margin-right: 0.5em;
          padding-right: 0.5em;
          border-right: 1px $light solid;
        }
        .content {
          grid-column: 2;
        }
      }
    }
    &-top-bar,
    &-bottom-bar {
      padding: 1rem;
    }

    &-top-bar {
      // Gradient for webkit browsers
      background-image: -webkit-radial-gradient(circle at center, $accent-dark, $base-dark);
      // Gradient for other browsers
      background-image: linear-gradient(to top, $base-dark, $base-darker);
      &-left {
        grid-column: 1;
        grid-row: 1;
      }
      &-center {
        grid-column: 2;
        grid-row: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
      &-right {
        grid-column: 3;
        grid-row: 1;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
      }
    }
    &-content {
      margin: 0 0.5rem 0 0.5rem;
      grid-column: 1 / end;
      grid-row: 2;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex-grow: 1;
    }
    &-bottom-bar {
      // Gradient for webkit browsers
      background-image: -webkit-radial-gradient(circle at center, $accent-dark, $base-dark);
      // Gradient for other browsers
      background-image: linear-gradient(to bottom, $base-dark, $base-darker);

      &-button-group {
        background-color: red !important;
        display: flex;
        flex-flow: row nowrap;
      }
      &-left {
        grid-column: 1;
        grid-row: 3;
      }
      &-center {
        grid-column: 2;
        grid-row: 3;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
      &-right {
        grid-column: 3;
        grid-row: 3;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
      }
    }
  }
}
.call-video-screens {
  div[class^="video-screen-"],
  div[class*=" video-screen-"] {
    border-radius: $video-radius;
    display: flex;
    flex-direction: column;
    &.no-video {
      // Basic color to gracefully degrade
      background-color: $base-dark;
      // Gradient for webkit browsers
      background-image: -webkit-radial-gradient(circle at center, $base-dark, lighten($accent-dark, 10%));
      // Gradient for other browsers
      background-image: radial-gradient(circle at center, $base-dark, lighten($accent-dark, 10%));
    }
    .status {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      > .text {
        background-color: rgba($base-dark, 50%);
        border-radius: $video-radius 0 $video-radius 0;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
      }
      > .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        > .icon {
          width: 2rem;
          height: 2rem;
          background-color: rgba($base-dark, 50%);
          display: flex;
          align-items: center;
          justify-content: center;
          &:first-child {
            border-radius: 0 0 0 $video-radius;
          }
          &:last-child {
            border-radius: 0 $video-radius 0 0;
          }
          &:only-child {
            border-radius: 0 $video-radius 0 $video-radius;
          }
          > .icon {
            height: 60%;
            width: 60%;
          }
        }
      }
    }
    .body {
      position: relative;
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      border-radius: 0 0 $video-radius $video-radius;
      > .user-avatar {
        transform: translateY(-1rem);
      }
    }
  }
}
.call-video-screens.slideshow {
  min-width: 100%;
  min-height: 100%;
  max-width: 100vw;
  max-height: 78vh;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-template-areas:
    "slide slide slide slide slide bar"
    "slide slide slide slide slide bar"
    "slide slide slide slide slide bar"
    "slide slide slide slide slide bar"
    "slide slide slide slide slide bar"
    "slide slide slide slide slide bar";
  gap: 0.5em;
  justify-content: stretch;
  align-content: stretch;

  .selected {
    grid-area: slide;
  }
  .bar {
    grid-area: bar;
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    @include cool-scrollbar();
    div[class^="video-screen-"],
    div[class*=" video-screen-"] {
      width: auto;
      height: calc(22% - 0.33rem);
      min-height: calc(22% - 0.33rem);
      max-height: calc(22% - 0.33rem);
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: initial;
      }
    }
  }

  &.n-1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "slide";
    .bar {
      display: none;
    }
  }
}
.call-video-screens.grid {
  // Define general rule for when the video windows will exceed the forseen configurations
  overflow-y: auto;

  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 78vh;
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
  justify-content: center;
  align-content: flex;
  // margin-right: -0.5rem;
  div[class^="video-screen-"],
  div[class*=" video-screen-"] {
    width: calc(20% - 0.5rem);
    height: calc(25% - 0.33rem);
  }

  @include cool-scrollbar();

  &.n-1,
  &.n-2,
  &.n-3,
  &.n-4,
  &.n-5,
  &.n-6,
  &.n-7,
  &.n-8,
  &.n-9,
  &.n-10,
  &.n-11,
  &.n-12,
  &.n-13,
  &.n-14,
  &.n-15,
  &.n-16 {
    min-width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(24, 1fr);
    gap: 0.5em;
    justify-content: stretch;
    align-content: stretch;
    div[class^="video-screen-"],
    div[class*=" video-screen-"] {
      width: 100%;
      height: 100%;
    }
  }
  // Custom configurations
  &.n-1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  &.n-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  &.n-3,
  &.n-4 {
    .video-screen-1 {
      grid-column: 1/13;
      grid-row: 1/13;
    }
    .video-screen-2 {
      grid-column: 13 / end;
      grid-row: 1/13;
    }
  }
  &.n-3 {
    .video-screen-3 {
      grid-column: 7/19;
      grid-row: 13 / end;
    }
  }
  &.n-4 {
    .video-screen-3 {
      grid-column: 1/13;
      grid-row: 13 / end;
    }
    .video-screen-4 {
      grid-column: 13 / end;
      grid-row: 13 / end;
    }
  }
  &.n-5,
  &.n-6 {
    .video-screen-1 {
      grid-column: 1/9;
      grid-row: 1/13;
    }
    .video-screen-2 {
      grid-column: 9/17;
      grid-row: 1/13;
    }
    .video-screen-3 {
      grid-column: 17 / end;
      grid-row: 1/13;
    }
  }
  &.n-5 {
    .video-screen-4 {
      grid-column: 5/13;
      grid-row: 13 / end;
    }
    .video-screen-5 {
      grid-column: 13/21;
      grid-row: 13 / end;
    }
  }
  &.n-6 {
    .video-screen-4 {
      grid-column: 1/9;
      grid-row: 13 / end;
    }
    .video-screen-5 {
      grid-column: 9/17;
      grid-row: 13 / end;
    }
    .video-screen-6 {
      grid-column: 17 / end;
      grid-row: 13 / end;
    }
  }
  &.n-7,
  &.n-8,
  &.n-9 {
    .video-screen-1 {
      grid-column: 1/9;
      grid-row: 1/9;
    }
    .video-screen-2 {
      grid-column: 9/17;
      grid-row: 1/9;
    }
    .video-screen-3 {
      grid-column: 17 / end;
      grid-row: 1/9;
    }
    .video-screen-4 {
      grid-column: 1/9;
      grid-row: 9/17;
    }
    .video-screen-5 {
      grid-column: 9/17;
      grid-row: 9/17;
    }
    .video-screen-6 {
      grid-column: 17 / end;
      grid-row: 9/17;
    }
  }
  &.n-7 {
    .video-screen-7 {
      grid-column: 9/17;
      grid-row: 17 / end;
    }
  }
  &.n-8 {
    .video-screen-7 {
      grid-column: 5/13;
      grid-row: 17 / end;
    }
    .video-screen-8 {
      grid-column: 13/21;
      grid-row: 17 / end;
    }
  }
  &.n-9 {
    .video-screen-7 {
      grid-column: 1/9;
      grid-row: 17 / end;
    }
    .video-screen-8 {
      grid-column: 9/17;
      grid-row: 17 / end;
    }
    .video-screen-9 {
      grid-column: 17 / end;
      grid-row: 17 / end;
    }
  }
  &.n-10,
  &.n-11,
  &.n-12 {
    // width 4 / height 3
    // first row
    .video-screen-1 {
      grid-column: 1/7;
      grid-row: 1/9;
    }
    .video-screen-2 {
      grid-column: 7/13;
      grid-row: 1/9;
    }
    .video-screen-3 {
      grid-column: 13/19;
      grid-row: 1/9;
    }
    .video-screen-4 {
      grid-column: 19 / end;
      grid-row: 1/9;
    }
    // second row
    .video-screen-5 {
      grid-column: 1/7;
      grid-row: 9/17;
    }
    .video-screen-6 {
      grid-column: 7/13;
      grid-row: 9/17;
    }
    .video-screen-7 {
      grid-column: 13/19;
      grid-row: 9/17;
    }
    .video-screen-8 {
      grid-column: 19 / end;
      grid-row: 9/17;
    }
  }
  &.n-10 {
    .video-screen-9 {
      grid-column: 7/13;
      grid-row: 17 / end;
    }
    .video-screen-10 {
      grid-column: 13/19;
      grid-row: 17 / end;
    }
  }
  &.n-11 {
    .video-screen-9 {
      grid-column: 4/10;
      grid-row: 17 / end;
    }
    .video-screen-10 {
      grid-column: 10/16;
      grid-row: 17 / end;
    }
    .video-screen-11 {
      grid-column: 16/22;
      grid-row: 17 / end;
    }
  }
  &.n-12 {
    .video-screen-9 {
      grid-column: 1/7;
      grid-row: 17 / end;
    }
    .video-screen-10 {
      grid-column: 7/13;
      grid-row: 17 / end;
    }
    .video-screen-11 {
      grid-column: 13/19;
      grid-row: 17 / end;
    }
    .video-screen-12 {
      grid-column: 19 / end;
      grid-row: 17 / end;
    }
  }
  &.n-13,
  &.n-14,
  &.n-15,
  &.n-16 {
    // first row
    .video-screen-1 {
      grid-column: 1/7;
      grid-row: 1/7;
    }
    .video-screen-2 {
      grid-column: 7/13;
      grid-row: 1/7;
    }
    .video-screen-3 {
      grid-column: 13/19;
      grid-row: 1/7;
    }
    .video-screen-4 {
      grid-column: 19 / end;
      grid-row: 1/7;
    }
    // second row
    .video-screen-5 {
      grid-column: 1/7;
      grid-row: 7/13;
    }
    .video-screen-6 {
      grid-column: 7/13;
      grid-row: 7/13;
    }
    .video-screen-7 {
      grid-column: 13/19;
      grid-row: 7/13;
    }
    .video-screen-8 {
      grid-column: 19 / end;
      grid-row: 7/13;
    }
    // third row
    .video-screen-9 {
      grid-column: 1/7;
      grid-row: 13/19;
    }
    .video-screen-10 {
      grid-column: 7/13;
      grid-row: 13/19;
    }
    .video-screen-11 {
      grid-column: 13/19;
      grid-row: 13/19;
    }
    .video-screen-12 {
      grid-column: 19 / end;
      grid-row: 13/19;
    }
  }
  &.n-13 {
    .video-screen-13 {
      grid-column: 10/16;
      grid-row: 19 / end;
    }
  }
  &.n-14 {
    .video-screen-13 {
      grid-column: 7/13;
      grid-row: 19 / end;
    }
    .video-screen-14 {
      grid-column: 13/19;
      grid-row: 19 / end;
    }
  }
  &.n-15 {
    .video-screen-13 {
      grid-column: 4/10;
      grid-row: 19 / end;
    }
    .video-screen-14 {
      grid-column: 10/16;
      grid-row: 19 / end;
    }
    .video-screen-15 {
      grid-column: 16/22;
      grid-row: 19 / end;
    }
  }
  &.n-16 {
    .video-screen-13 {
      grid-column: 1/7;
      grid-row: 19 / end;
    }
    .video-screen-14 {
      grid-column: 7/13;
      grid-row: 19 / end;
    }
    .video-screen-15 {
      grid-column: 13/19;
      grid-row: 19 / end;
    }
    .video-screen-16 {
      grid-column: 19 / end;
      grid-row: 19 / end;
    }
  }
}
