$menu_dark_option: $dark_option;
$menu_dark_theme_option: $dark_theme_option;

// Menu Font
$menu-link-ff: $alt-font-family !default;
$menu-link-fs: $fx-sz-15 !default;
$menu-link-fw: $fw-bold !default;
$menu-link-lh: 1.25rem !default;
$menu-link-ls: 0.01em !default;
$menu-link-case: none !default;

$menu-link-gap-y: .625rem !default;
$menu-link-gap-x: $sidebar-gap-x !default;

// Menu Sub/Child Font
$menu-sub-diff: true;
$menu-sub-link-ff: $base-font-family !default;
$menu-sub-link-fs: $fx-sz-14 !default;
$menu-sub-link-fw: $fw-normal !default;
$menu-sub-link-lh: $menu-link-lh !default;
$menu-sub-link-ls: normal !default;
$menu-sub-link-case: none !default;

$menu-sub-link-gap-y: .375rem !default;
$menu-sub-link-gap-x: $menu-link-gap-x !default;

// Link color
$menu-link-color-base: lighten($base-text, 12%) !default;
$menu-link-color-sub: lighten($base-text, 12%) !default;
$menu-link-color-hover: lighten($accent-color, 5%) !default;
$menu-border-color: $border-color !default;
$menu-border-dark-color: rgba($white, .12) !default;

// Menu Icon
$menu-icon-width-base: $menu-icon-width !default;
$menu-icon-font-size: 24px;
$menu-icon-color-base: $base-light !default;
$menu-icon-color-hover: $accent-color !default;
$menu-icon-width-small: $menu-icon-width-sm !default;
$menu-icon-font-size-small: 18px;

// Count
$menu-link-count-gap: .25rem !default;
$menu-link-count-fs: .85em !default;
$menu-link-count-color: $base-light !default;

// Menu Main Style
$menu-main-link-color-base: $base-text !default;
$menu-main-link-color-light: $base-light !default;
$menu-main-link-color-sub: $base-text !default;

$menu-main-link-gap-y: .5rem !default;
$menu-main-link-gap-x: 1.25rem !default;
$menu-main-link-gap-x: 1.25rem !default;

$menu-main-top-link-gap-y: 1.25rem !default;
$menu-main-top-link-gap-x: 1rem !default;

$menu-main-link-fs: $fx-sz-13 !default; // @desktop only
$menu-main-top-link-fs: $fx-sz-14 !default; // @desktop only

$menu-main-ddm-width: 200px !default;

// Menu SM Style
$menu-sm-link-gap-y: .25rem !default;
$menu-sm-link-color: $base-text !default;
$menu-sm-icon-width: 1.375rem;
$menu-sm-link-fs: $fx-sz-12;
$menu-sm-link-lh: 1.125rem;


// Menu Footer Style
$menu-footer-link-gap-y: .25rem !default;
$menu-footer-link-color: $base-light !default;
$menu-footer-icon-width: 1.375rem;
$menu-footer-link-fs: $fx-sz-12;
$menu-footer-link-lh: 1.125rem;
$menu-footer-icon-color: $accent-color;

// Icon
$menu-toggle-icon-ltr: $ni-chevron-right !default;
$menu-toggle-icon-rtl: $ni-chevron-left !default;
$menu-main-toggle-icon: $ni-chevron-down !default;
$menu-main-sub-toggle-icon-ltr: $ni-chevron-right !default;
$menu-main-sub-toggle-icon-rtl: $ni-chevron-left !default;

/////////////////////////
/// Menu Navigation
/////////////////////////
.nk-menu {
    li {
        a {
            vertical-align: middle;
            display: flex;
            position: relative;
            align-items: center;
            transition: color .3s, background-color .3s;
        }
    }

    &-item {
        padding: 2px 0;

        .nk-menu-sub & {
            padding: 1px 0;
        }

        &.has-sub {
            position: relative;
        }
    }

    >.nk-menu-item.menu-without-icon>.nk-menu-sub .nk-menu-link {
        padding-left: 0;
    }

    &-trigger.ms-3.p-1 {
        background-color: $white;
        border-radius: 0 50% 50% 0;
    }

    &-link {
        padding: $menu-link-gap-y (
            $menu-link-gap-x + 16) $menu-link-gap-y $menu-link-gap-x;
        color: $menu-link-color-base;
        font-family: $menu-link-ff;
        font-weight: $menu-link-fw;
        font-size: $menu-link-fs;
        letter-spacing: $menu-link-ls;
        text-transform: $menu-link-case;
        line-height: $menu-link-lh;

        &:hover,
        .active>& {
            color: $menu-link-color-hover;

            .count {
                color: $menu-link-color-hover;
            }
        }

        @if($menu-sub-diff==true) {
            .nk-menu-sub & {
                padding: $menu-sub-link-gap-y (
                    $menu-sub-link-gap-x + 16) $menu-sub-link-gap-y (
                    $menu-icon-width-base
                );
            font-family: $menu-sub-link-ff;
            font-weight: $menu-sub-link-fw;
            font-size: $menu-sub-link-fs;
            letter-spacing: $menu-sub-link-ls;
            text-transform: $menu-sub-link-case;
            line-height: $menu-sub-link-lh;
            color: $menu-link-color-sub;
        }
    }

    &.is-disable {
        cursor: default;

        &:hover,
        .active>& {
            color: $menu-link-color-base;

            .count {
                color: $menu-link-color-base;
            }
        }
    }

    .is-light & {
        color: $base-text;

    }

    @if($menu_dark_theme_option==true) {
        .is-theme & {
            color: darken($accent-light, 8%);

        }
    }

    &:hover,
    .active>& {
        .is-light & {
            color: $menu-link-color-hover;
        }

        @if($menu_dark_theme_option==true) {
            .is-theme & {
                color: $white;
            }
        }
    }

    span.small {
        color: $base-light;
        margin-left: .5rem;
    }
}

&-icon {
    font-weight: normal;
    letter-spacing: normal;
    width: $menu-icon-width-base;
    line-height: 1;
    flex-grow: 0;
    color: $menu-icon-color-base;

    @if($menu_dark_theme_option==true) {
        .is-theme & {
            color: darken($accent-light, 8%);
        }
    }

    svg,
    img {
        width: $menu-icon-width-base;
    }

    .icon {
        font-size: $menu-icon-font-size;
        letter-spacing: normal;
        vertical-align: middle;
        color: currentColor;
        transition: color .4s, background-color .4s;
    }

    .nk-menu-link:hover &,
    .nk-menu-item.active>.nk-menu-link &,
    .nk-menu-item.current-menu>.nk-menu-link & {
        color: $menu-link-color-hover;

        @if($menu_dark_theme_option==true) {
            .is-theme & {
                color: $white;
            }
        }
    }

    .nk-menu-link.is-disable:hover & {
        color: $menu-icon-color-base;

        @if($menu_dark_theme_option==true) {
            .is-theme & {
                color: $accent-light;
            }
        }
    }
}

&-text {
    flex-grow: 1;
    display: inline-block;
}

&-wrap {
    height: 0;
    overflow: hidden;
    transition: height .4s ease;
}

&-badge {
    border-radius: $border-radius-sm;
    padding: 0 .4rem;
    font-size: $fx-sz-11;
    color: $accent-500;
    background: $accent-100;
    position: absolute;
    top: 50%;
    right: $sidebar-gap-x;

    .has-sub & {
        right: $sidebar-gap-x;
    }

    transform: translateY(-50%);

    @if($menu_dark_option==true) {
        .is-dark & {
            color: $accent-400;
            background: lighten($sidebar-bg-dark-color, 10%);
        }
    }

    @if($menu_dark_theme_option==true) {
        .is-theme & {
            color: $accent-300;
            background: lighten($sidebar-bg-theme-color, 12%);
        }
    }

    .nk-menu-main .nk-menu-sub & {
        color: $accent-500;
        background: $accent-100;
    }
}

&-heading {
    padding: .75rem $menu-main-link-gap-x 0.5rem;
    color: $base-light;

    @if($menu_dark_theme_option==true) {
        .is-theme & {
            color: darken($accent-light, 15%);
        }
    }

    .nk-menu-item+& {
        padding-top: 1.25rem;
    }

    .overline-title {
        color: inherit;
    }

    span {
        font-size: $fx-sz-13;
        font-weight: $fw-normal;
    }
}

&-hr {
    margin: 1.25rem 0;
    border-bottom: 1px solid $menu-border-color;

    @if($menu_dark_option==true) {
        .is-dark & {
            border-color: $menu-border-dark-color;
        }
    }

    @if($menu_dark_theme_option==true) {
        .is-theme & {
            border-color: $menu-border-dark-color;
        }
    }

    +.nk-menu-heading {
        padding-top: 1rem;
    }
}

&-sub {
    padding-bottom: .25rem;

    .nk-menu-toggle+& {
        display: none;
    }

    .active>& {
        display: block;
    }

    .nk-menu-link {
        &:hover {
            color: $accent-color;

            @if($menu_dark_theme_option==true) {
                .is-theme & {
                    color: $white;
                }
            }
        }
    }

    .active>.nk-menu-link {
        color: $accent-color;

        @if($menu_dark_theme_option==true) {
            .is-theme & {
                color: $white;
            }
        }
    }

    .nk-menu-icon {
        width: 24px;
        margin-top: -1px;

        .icon {
            margin-top: -1px;
            font-size: 1.2em;
        }
    }
}

&-toggle {
    position: relative;

    &:after {
        position: absolute;
        font-family: $nk-dashlite-font;
        top: 50%;
        right: 1.25rem;
        content: $menu-toggle-icon-ltr;
        transform: translateY(-50%);
        font-size: 1rem;
        color: $base-light;
        transition: transform .3s, color .1s;

        @if($menu_dark_option==true or $menu_dark_theme_option==true) {

            .is-dark &,
            .is-theme & {
                color: rgba($white, 0.4);
            }
        }
    }

    .has-sub.active>& {
        &:after {
            transform: translateY(-50%) rotate(90deg);
        }
    }
}

.count {
    display: inline-block;
    font-size: $menu-link-count-fs;
    margin-left: $menu-link-count-gap;
    color: $menu-link-count-color;
    line-height: 1;
}

&-icon-colored {
    .nk-menu-icon {
        color: $menu-icon-color-hover;
    }
}

&-tooltip {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
}

//////////////////////////
// Main Menu @Top Nav
//////////////////////////
.nk-menu {
    padding-top: 0.75rem;

    &.mobile {
        height: 100%;
    }

    .nk-menu {
        &-link {
            color: $menu-main-link-color-base;
            padding-left: $menu-link-gap-x;
        }

        &-sub {
            padding: 0;
            margin: 0;
            transition: none;

            .nk-menu-link {
                color: $menu-main-link-color-base;
            }

            .nk-menu-sub {
                border-left: 1px solid $menu-border-color;
                margin: .25rem 0 .25rem $menu-link-gap-x;
                padding-bottom: 0;

                .nk-menu-link {
                    padding-left: .875rem;
                }
            }
        }

        &-link:hover,
        &-link:focus,
        &-item.active>.nk-menu-link,
        &-item.current-menu>.nk-menu-link,
        &-item:hover>.nk-menu-link {
            color: $menu-link-color-hover;
        }
    }

    >li {
        >.nk-menu-link {
            text-transform: uppercase;
            letter-spacing: 0.05rem;
        }
    }

    &.ui-s2 {
        >li {
            >.nk-menu-link {
                font-family: $base-font-family;
                font-weight: $fw-medium;
                text-transform: initial;
                letter-spacing: - 0.01rem;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .nk-menu {
        max-height: calc(100vh - #{$header-height});
        overflow: auto;
    }
}

@include media-breakpoint-up(lg) {
    .nk-menu {
        padding: 0 1.5rem 0 2rem;

        >li {
            padding: 0;
            display: inline-block;

            @if($menu-main-link-gap-x !=$menu-main-top-link-gap-x) {
                &.nk-menu-item {
                    padding: 0 $menu-main-top-link-gap-x;

                    >.nk-menu-link {
                        padding: $menu-main-top-link-gap-y 0;

                        @if($menu-main-link-fs !=$menu-main-top-link-fs) {
                            font-size: $menu-main-top-link-fs;
                        }
                    }
                }
            }

            >.nk-menu-link {
                &:before {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 3px;
                    background: $menu-link-color-hover;
                    border-radius: 3px 3px 0 0;
                    opacity: 0;
                }
            }

            &.nk-menu-item.active,
            &.nk-menu-item.current-menu {
                >.nk-menu-link:before {
                    opacity: 1;
                }
            }

            >.nk-menu-sub {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        .nk-menu {
            &-link {
                padding: $menu-main-link-gap-y $menu-main-link-gap-x;
                font-size: $menu-main-link-fs;
            }

            // &-icon {
            //     width: 36px;
            //     height: 36px;
            //     position: relative;
            //     margin-top: -12px;

            //     .icon.ni {
            //         line-height: calc(1.5rem + 24px);
            //         margin-top: calc(12px - $menu-main-link-gap-y);
            //         margin-bottom: calc(12px - $menu-main-link-gap-x);
            //         font-size: 36px;
            //     }
            // }

            &-text {
                line-height: 1.5rem;
            }

            &-item {
                .nk-menu-toggle {
                    &:after {
                        line-height: 1;
                        position: static;
                        transform: translateY(0);
                        content: $menu-main-toggle-icon;
                        vertical-align: middle;
                        color: rgba($base-light, .7);
                        margin-left: 0.5rem;
                        margin-right: -2px;
                        margin-top: -1px;
                        transition: color .1s;
                    }
                }

                &:hover {
                    >.nk-menu-sub {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                    }
                }
            }

            &-sub {
                position: absolute;
                left: 0;
                top: 100%;
                width: $menu-main-ddm-width;
                background: $white;
                border: 1px solid $border-light;
                box-shadow: $box-shadow-sm;
                border-radius: $border-radius;
                padding: .5rem 0;
                opacity: 0;
                visibility: hidden;
                margin-top: 6px;
                transition: opacity .4s, margin .2s;
                display: block !important;

                .nk-menu-link {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }

                .nk-menu-sub {
                    margin: 0;
                    margin-top: 6px;
                    margin-left: -0.75rem;
                    left: 100%;
                    top: - 9px;
                    padding: .5rem 0;
                    border: 1px solid $border-light;

                    .nk-menu-link {
                        padding-left: 1.5rem;
                    }
                }

                .nk-menu-item {
                    &:hover>a {
                        background: $lighter;
                    }
                }

                .nk-menu-toggle {
                    &:after {
                        content: $menu-main-sub-toggle-icon-ltr;
                    }
                }
            }
        }
    }

    // DARK Version
    @if($menu_dark_option==true or $menu_dark_theme_option==true) {

        .is-dark,
        .is-theme {
            .nk-menu {
                >li {
                    >.nk-menu-link {
                        &:before {
                            border-radius: 0;
                            bottom: auto;
                            top: 0;
                        }
                    }
                }
            }
        }
    }

    @if($menu_dark_option==true) {
        .is-dark {
            .nk-menu {
                >li {
                    >.nk-menu-link {
                        color: lighten($base-light, 10%);

                        &.nk-menu-toggle:after {
                            color: rgba($base-light, .7);
                        }
                    }

                    &:hover>.nk-menu-link,
                    &.active>.nk-menu-link,
                    &.current-menu>.nk-menu-link,
                    &>.nk-menu-link:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    @if($menu_dark_theme_option==true) {
        .is-theme {
            .nk-menu {
                >li {
                    >.nk-menu-link {
                        color: $accent-light;

                        &.nk-menu-toggle:after {
                            color: rgba($accent-light, .7);
                        }
                    }

                    &:hover>.nk-menu-link,
                    &.active>.nk-menu-link,
                    &.current-menu>.nk-menu-link,
                    &>.nk-menu-link:hover {
                        color: $white;
                    }
                }
            }
        }
    }
}