//////////////////////////
/// Scheme
//////////////////////////
.nk-iv-scheme {
    &-list {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }
    &-item {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        &:not(:first-child) {
            margin-top: 1.5rem;
        }
        background-color: $white;
        border-radius: $border-radius;
        border: 1px solid $border-color;
        padding: 1.25rem;
        .amount {
            color: $base-color;
            font-weight: $fw-bold;
        }
        .amount-ex {
            color: $accent-color;
            font-weight: $fw-normal;
        }
        div{
            flex-grow: 1;
        }
    }
    &-icon {
        width: 40px;
        height: 40px;
        text-align: center;
        background: $lighter;
        border-radius: 50%;
        margin-right: 1rem;
        flex-shrink: 0;
        flex-grow: 0 !important;
        color: $base-text;
        &.is-running{
            color: $accent-color;
            background: rgba($accent-color, 0.08);
        }
        &.is-pause{
            color: $warning;
            background: rgba($warning, 0.08);
        }
        &.is-pending{
            color: $info;
            background: rgba($info, 0.08);
        }
        &.is-cancel{
            color: $danger;
            background: rgba($danger, 0.08);
        }
        .icon {
            line-height: 40px;
            font-size: 1.3rem;
        }
    }
    &-info {
        padding-right: 0.75rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &-name {
        font-weight: $fw-bold;
        font-size: 0.875rem;
        color: $base-color;
    }
    &-desc {
        color: $base-light;
        font-size: 0.8125rem;
    }
    &-amount{
        display: none;
        a{
            display: none;
        }
        div{
            flex-grow: 0;
            padding: 0 1rem;
        }
    }
    &-term {
        display: none;
        div {
            flex-grow: 0;
            padding: 0 1.5rem;
        }
    }
    &-start{
        position: relative;
        &:after{
            border: none !important;
            font-family: $nk-dashlite-font;
            vertical-align: middle;
            position: absolute;
            top: 50%;
            left: 100%;
            margin-left: -0.7rem;
            content: $ni-arrow-long-right;
            transform: translateY(-50%);
            font-size: 18px;
            color: $base-light;
        }
    }
    &-label {
        display: block;
        font-size: 0.8125rem;
    }
    &-value {
        font-weight: $fw-medium;
    }
    &-more {
        margin-left: auto;
        margin-right: -1rem;
        flex-grow: 0 !important;
        border-radius: 50%;
        transition: background-color 0.4s;
        &:hover {
            background-color: $lighter;
        }
        .btn{
            margin-left: auto;
            color: $base-light;
            width: 40px;
            height: 40px;
            .icon {
                font-size: 18px;
            }
        }
    }
    &-progress {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .progress-bar {
            width: 0px;
            height: 3px;
            border-radius: 0 $border-radius-sm $border-radius-sm $border-radius-sm;
            &[data-progress="100"] {
                border-radius: 0 0 $border-radius-sm $border-radius-sm;
            }
        }
    }
    &-order{
        display: flex;
        flex-direction: column;
        .nk-iv-scheme-value {
            order: -1;
        }
    }
}

@include media-breakpoint-up(sm){
    .nk-iv-scheme {
        &-list {
            &:not(:last-child) {
                margin-bottom: 2.75rem;
            }
        }
        &-item {
            padding: 1.5rem;
        }
        &-amount {
            display: block;
            &-a{
                display: none;
            }
            div {
                padding: 0;
            }
        }
    }
}
@media (min-width: 768px){
    .nk-iv-scheme{
        &-amount{
            display: flex !important;
            &-a{
                display: flex;
            }
            div {
                padding: 0 1rem;
            }
        }
    }
}
@media (min-width: 992px){
    .nk-iv-scheme{
        &-icon{
            width: 50px;
            height: 50px;
            .icon{
                line-height: 50px;
                font-size: 1.5rem;
            }
        }
        &-term {
            display: flex !important;
        }
        &-more {
            .btn {
                width: 44px;
                height: 44px;
            }
        }
    }
}
@media (min-width: 1200px){
    .nk-iv-scheme{
        &-term, &-amount {
            div {
                flex-grow: 0;
                padding: 0 2rem;
            }
        } 
        &-info {
            padding-right: 2rem;
        }
    }
}