.data {
    &-list {
        &:not(:first-child) {
            margin-top: 1.5rem;
        }
    }

    &-item {
        position: relative;
        padding: 1rem 1.25rem;
        display: flex;
        align-items: center;

        .icon {
            color: $base-light;
            text-align: center;
            height: 32px;
            vertical-align: middle;
            line-height: 32px;
            display: inline-block;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $border-light;
        }

        &[data-toggle] {
            cursor: pointer;
        }

        &:hover {
            .data-label {
                color: $base-text;
            }

            .data-value,
            .icon {
                color: $base-color;
            }
        }
    }

    &-head {
        padding: 0.5rem 1.25rem;
        margin-bottom: 0.25rem;
        background-color: $light-100;
        border-radius: $border-radius;

        &.is-plain {
            margin-bottom: 0;

            &:first-child {
                margin-top: 1rem;
            }

            background-color: transparent;
        }

        .title {
            color: $base-text;
        }
    }

    &-col {
        flex-grow: 1;

        span:not(.data-more) {
            display: inline-block;
            transition: color .3s;
        }

        &-end {
            flex-grow: 0;
            margin-left: auto;
        }
    }

    &-label {
        color: $base-light;
        width: 100%;
        flex-shrink: 0;
    }

    &-value {
        color: $base-text;
        font-size: 0.9375rem;
    }

    &-more {
        position: relative;
        z-index: 4;
        text-align: center;
        line-height: 28px;
        height: 28px;
        width: 28px;
        display: inline-grid;
        align-content: center;

        &:before {
            position: absolute;
            z-index: -1;
            height: 20px;
            width: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transform-origin: 50% 50%;
            content: '';
            background-color: $light-200;
            border-radius: 50%;
            opacity: 0;
            transition: all .3s;

            .data-item:hover & {
                opacity: 1;
                height: 40px;
                width: 40px;
            }
        }

        &.disable {
            &:before {
                display: none;
            }
        }
    }
}

.data-list-s2 {
    &:not(:first-child) {
        margin-top: 1rem;
    }

    .data {

        &-item,
        &-head {
            padding-left: 0;
            padding-right: 0;
            background: transparent;
        }

        &-head {
            border-bottom: 1px solid $border-color;
        }

        &-item:not(:last-child) {
            border-bottom-color: $border-color;
        }

        &-label {
            color: $base-text;
        }

        &-placeholder {
            color: $base-light;
        }
    }
}

@include media-breakpoint-up(md) {
    .data {
        &-list {
            &:not(:first-child) {
                margin-top: 2rem;
            }

            &-s2 {
                &:not(:first-child) {
                    margin-top: 1.25rem;
                }
            }
        }

        &-item {
            padding: 1.5rem 1.25rem;
        }

        &-col {
            display: flex;
            align-items: center;

            &-end {
                width: 200px;
                text-align: right;
                justify-content: flex-end;
            }
        }

        &-label {
            &:not([class^="w-"]) {
                width: 50%;
            }
        }
    }
}

@media (min-width:1200px) and (max-width:1359px) {
    .card-aside-wrap {
        .data-list {
            .data-col {
                flex-wrap: wrap;
            }

            .data-label {
                width: 100%;
            }
        }
    }
}

.is-compact .data-item {
    padding-top: .75rem;
    padding-bottom: .75rem;
}

.table-body-horizontal-scroll {
    overflow-x: auto;
    max-width: calc(100vw - 32rem);

    @media (max-width: 992px) {
        max-width: 80vw;
    }
}

.is-medium .data-item {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
}