$link-active-icon: $ni-check-thick !default;
$link-list-menu-icon-ltr: $ni-chevron-right !default;
$link-list-menu-icon-rtl: $ni-chevron-left !default;

// Used for Extend.
.link-bdr-t {
    border-top: 1px solid $border-light;
}

.link-bdr-b {
    border-bottom: 1px solid $border-light;
}

.link-pd-sm {
    padding: 6px 1rem;
}

.link-act-check {
    &:after {
        content: $link-active-icon;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        font-family: $nk-dashlite-font;
    }
}


/// Link Check
/////////////////////////////////////
.link-check {
    padding: .5rem 0;

    +.link-check {
        @extend .link-bdr-t;
    }

    .divider {
        margin: .5rem 0;
    }

    li {
        display: block;

        >span,
        a {
            display: block;
            line-height: 1.25rem;
            padding: .35rem 1rem;
        }

        >span {
            text-transform: uppercase;
            color: $base-color;
            font-weight: 700;
            font-size: $fx-sz-12;
            letter-spacing: 1px;
        }

        a {
            display: flex;
            align-items: center;
            color: $base-text;
            font-size: $fx-sz-13;
            position: relative;

            &:hover {
                color: $accent-color;
                background: $lighter;
            }
        }

        .icon {
            width: 1.75rem;
            font-size: 1.125rem;
        }

        &.active>a,
        a.active {
            @extend .link-act-check;
        }
    }
}


/// Link Clean / Tidy
/////////////////////////////////////
.link-tidy {
    li+li {
        @extend .link-bdr-t;
    }

    &.no-bdr {
        padding: .5rem 0;

        li:not(.divider) {
            border-top-width: 0;
        }
    }

    .divider {
        margin: .5rem 0;
    }

    li {
        position: relative;
        padding: .625rem 1.1rem;
        line-height: 1.3rem;
    }

    &.sm {
        li {
            @extend .link-pd-sm;
        }
    }
}


/// Link List
/////////////////////////////////////
.link-list {
    padding: .75rem 0;

    +.link-list {
        @extend .link-bdr-t;
    }

    .divider {
        margin: .5rem 0;
    }

    a {
        display: flex;
        align-items: center;
        color: $base-text;
        font-size: $fx-sz-13;
        line-height: 1.4rem;
        font-weight: 500;
        padding: .575rem 0;
        position: relative;

        &.compact {
            // line-height: 1rem;
            // font-size: $fx-sz-10;
            font-weight: normal;
            padding: 0;
        }

        &:hover {
            color: $primary;
        }

        .icon {
            font-size: 1.125rem;
            width: 1.5rem;
            line-height: 1;
        }
    }
}

/// Link List Options
/////////////////////////////////////
.link-list-opt {
    li+li {
        @extend .link-bdr-t;
    }

    &.no-bdr {
        padding: .5rem 0;

        li:not(.divider) {
            border-top-width: 0;
        }
    }

    .divider {
        margin: .5rem 0;
    }

    a {
        display: flex;
        align-items: center;
        padding: .625rem 1.25rem;
        font-size: $fx-sz-12;
        font-weight: $fw-medium;
        color: $base-text;
        transition: all .4s;
        line-height: 1.3rem;
        position: relative;

        &:hover {
            color: $accent-color;
            background: $lighter;
        }

        .icon {
            font-size: 1.125rem;
            width: 1.75rem;
            opacity: .8;
        }
    }

    li.active>a,
    a.active {
        @extend .link-act-check;
    }

    li.disabled>a,
    a.disabled {
        pointer-events: none;
        color: $base-light;
        opacity: 0.7;
    }

    .opt-head {
        padding: .25rem 1.25rem;
        color: $base-light;
    }

    &.ui-v2 {
        a {
            font-weight: $fw-normal;
        }
    }

    &.no-bdr+.link-check {
        margin-top: -0.75rem;

    }

    &.ui-colored {

        li.active>a,
        a.active {
            color: $accent-color;
        }
    }
}

/// Link List Menu
/////////////////////////////////////
.link-list-menu {
    padding: .75rem 0;

    .divider {
        margin: .5rem 0;
    }

    a {
        position: relative;
        display: flex;
        align-items: center;
        padding: .75rem 1.25rem;
        font-weight: 500;
        color: $base-text;
        transition: all .4s;
        line-height: 1.3rem;

        &:after {
            position: absolute;
            font-size: 1rem;
            font-family: $nk-dashlite-font;
            content: $link-list-menu-icon-ltr;
            right: 1.25rem;
            color: $base-light;
            transition: .3s ease;
        }

        .icon {
            font-size: 1.125rem;
            width: 1.75rem;
            opacity: .8;
            color: $base-light;
            margin-top: -2px;
        }
    }

    li.active>a,
    a.active,
    a:hover,
    li.active>a .icon,
    a.active .icon,
    a:hover .icon,
    li.active>a:after,
    a.active:after,
    a:hover:after {
        color: $accent-color;
    }

    &.nav {
        display: block;
    }
}

@include media-breakpoint-up(sm) {
    .link-list-menu {
        a {
            padding: 1rem 1.5rem;
        }
    }
}


/// Link List Plain
/////////////////////////////////////
.link-list-plain {
    li+li {
        @extend .link-bdr-t;
    }

    &.no-bdr {
        padding: .5rem 0;

        li {
            border-top-width: 0;
        }
    }

    .divider {
        margin: .5rem 0;
    }

    a {
        display: flex;
        align-items: center;
        padding: .5rem 1.25rem;
        color: $base-text;
        transition: all .4s;
        line-height: 1.4rem;
        position: relative;

        &:hover {
            color: $accent-color;
            background: $lighter;
        }

        .icon {
            font-size: 1.125rem;
            width: 1.75rem;
            opacity: .8;
            color: $accent-color;
        }
    }

    li.active>a,
    a.active {
        color: $accent-color;
        background: $lighter;
    }

    &.sm {
        a {
            @extend .link-pd-sm;
        }
    }

    &.text-center {
        a {
            justify-content: center;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }
    }
}

@each $li_col in $li_cols {
    .link-list-plain.li-col#{$li_col}x {
        >li {
            border-right: 1px solid $border-light;
            border-bottom: 1px solid $border-light;
            border-top: 0;
        }

        >li:nth-child(#{$li_col}n) {
            border-right: none;
        }
    }
}


/// Link List Plain
/////////////////////////////////////
.link-list-template {
    a {
        font-weight: $fw-normal;
    }
}